import moment from 'moment';
import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';

import { SHORT_DATE_FORMAT, DATE_FORMAT } from 'ready/utils/constants';
import { messageChannels, filterKeys } from 'constants.js';
import { sanitizePayload } from 'utils/index';
import { formatDate } from 'ready/utils';

export const conversationAssigneesPayload = assignees => ({ assignees });
export const inquiryStatusPayload = (status, decline_reason = null) => ({
  status: status,
  decline_reason: decline_reason,
});
export const guestCheckInPayload = time => ({ guest_checkin_at: time });
export const guestCheckOutPayload = time => ({ guest_checkout_at: time });
export const cancelGuestAutoReviewPayload = () => ({ auto_review_sent_or_disabled: true });
export const conversationStatusPayload = status => ({ status });
export const conversationFlagPayload = flag => ({ flag });
export const conversationAutomaticAIEnabledPayload = enabled => ({ automatic_ai_enabled: enabled });

/**
 * Payload to snooze conversation
 * @param {moment} dateTime date time until conversation should remain snoozed
 */
export const conversationSnoozePayload = dateTime => ({ snooze_until: dateTime.utc().format() });
export const guestLanguagePayload = language => ({ language });
export const guestCheckInMissionCommentPayload = check_in_external_comment => ({ check_in_external_comment });
export const guestCheckInMissionTypePayload = traveller_status => ({ traveller_status });
export const automatedMessagePayload = (automatedMessage, booking = {}) => ({
  enable: !automatedMessage.enable,
  reservation_id: booking.id,
});

const noteTemplate = note => `
CALL NOTES:
date: ${moment().format(SHORT_DATE_FORMAT)}
note: ${note}
`;

export const callCenterNotePayload = (conversationId, note, call_sid = '') => ({
  content: noteTemplate(note),
  channel: messageChannels.INTERNAL.value,
  conversation: conversationId,
  call_sid: call_sid,
});

export const callCenterCallReasonPayload = (conversationId, callReason) => ({
  content: `CALL REASON: ${callReason}`,
  channel: messageChannels.INTERNAL.value,
  conversation: conversationId,
});

const {
  CITIES,
  LANGUAGES,
  PROPERTY_NAME,
  CATEGORY_ID,
  RESERVATION_ID,
  RESERVATION_STATUSES,
  INQUIRY_STATUSES,
  RESERVATION_PLATFORMS,
  GUEST_FIRST_NAME,
  GUEST_LAST_NAME,
  GUEST_PHONE_NUMBER,
  RESERVATION_CREATED_FROM,
  RESERVATION_CREATED_TO,
  RESERVATION_CHECK_IN_FROM,
  RESERVATION_CHECK_IN_TO,
  RESERVATION_CHECK_OUT_FROM,
  RESERVATION_CHECK_OUT_TO,
  RESERVATION_HAS_REVIEW,
  FLAG_ID,
} = filterKeys;

const getDateRangeParam = (from, to) => {
  if (!from && !to) return null;
  return `${from || ''},${to || ''}`;
};

export const filtersParams = createSelector(
  [filters => filters],
  filters => {
    const prePayload = {
      filter: filters[CATEGORY_ID],
      language: filters[LANGUAGES],
      cities: filters[CITIES],
      reservations__id: filters[RESERVATION_ID],
      reservations__status: filters[RESERVATION_STATUSES],
      rentals: filters[PROPERTY_NAME],
      inquiries__status: filters[INQUIRY_STATUSES],
      platform: filters[RESERVATION_PLATFORMS],
      guest__first_name: filters[GUEST_FIRST_NAME],
      guest__last_name: filters[GUEST_LAST_NAME],
      guest__guest_phone_number: filters[GUEST_PHONE_NUMBER],
      reservations__created_at: getDateRangeParam(
        formatDate(filters[RESERVATION_CREATED_FROM], DATE_FORMAT, 'DD/MM/YYYY'),
        formatDate(filters[RESERVATION_CREATED_TO], DATE_FORMAT, 'DD/MM/YYYY'),
      ),
      reservations__start_date: getDateRangeParam(
        formatDate(filters[RESERVATION_CHECK_IN_FROM], DATE_FORMAT, 'DD/MM/YYYY'),
        formatDate(filters[RESERVATION_CHECK_IN_TO], DATE_FORMAT, 'DD/MM/YYYY'),
      ),
      reservations__end_date: getDateRangeParam(
        formatDate(filters[RESERVATION_CHECK_OUT_FROM], DATE_FORMAT, 'DD/MM/YYYY'),
        formatDate(filters[RESERVATION_CHECK_OUT_TO], DATE_FORMAT, 'DD/MM/YYYY'),
      ),
      has_review: filters[RESERVATION_HAS_REVIEW],
      flag__id: filters[FLAG_ID],
    };

    return sanitizePayload(prePayload);
  },
);

export const stringifyFiltersForPreset = filters => {
  const filtersToSave = fromJS(filters)
    .filter((value, key) => {
      if (key === filterKeys.CATEGORY_ID) return false;
      return List.isList(value) ? value.size : value;
    })
    .toJS();
  return JSON.stringify(filtersToSave);
};
