import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css?family=Inter:300,400,500,700");

  html,
  body,
  form,
  input,
  textarea,
  button {
    font-family: "Inter";
    -webkit-font-smoothing: antialiased;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    background-color: ${props => props.theme.colors.white};
    overflow: hidden;
  }

  html {
    font-size: 10px;
  }

  ::selection {
    background-color: ${props => props.theme.colors.b100};
  }

  * {
    box-sizing: border-box;
  }
`;

export default GlobalStyles;
