import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { BOOKING_PLATFORMS } from 'constants.js';

import { ChatNotification } from 'components';
import { Text, Button, Icon, DropdownButton, DropdownList, DropdownListItem } from 'ready/components';

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const TitleWtapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing.m};
`;

const ActionsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${props => props.theme.spacing.xs};
`;

const iconMap = {
  airbnb: Icon.icons.v2.AIRBNB_LOGO,
  vrbo: Icon.icons.v2.VRBO_LOGO,
};

const vrboDeclineReasons = {
  cancelledByGuest: 'cancelled_by_guest',
  cancelledByOwner: 'cancelled_by_owner',
};

const AcceptEnquiry = ({ t, onDecline, onAccept, onSendSpecialOffer, disabled, platformName }) => (
  <ChatNotification t={t} data-qa="Notification:AcceptEnquiry__ChatNotification" collapseDisabled>
    <ContentWrapper>
      <TitleWtapper>
        <Icon icon={iconMap[platformName]} size={Icon.sizes.XL} color={Icon.colors.b900_v2} />
        <Text bold data-qa="AcceptEnquiry:Text">
          {t.acceptEnquiryQuestion}
        </Text>
      </TitleWtapper>
      <ActionsWrapper>
        {platformName === BOOKING_PLATFORMS.AIRBNB && (
          <Button
            inline
            size={Button.sizes.S}
            variation={Button.variations.OUTLINE}
            onClick={onSendSpecialOffer}
            disabled={disabled || !onSendSpecialOffer}
            hint={{ enabled: true, text: t.specialOfferHint }}
            data-qa="AcceptEnquiry:Button--specialOffer"
          >
            {t.specialOffer}
          </Button>
        )}
        <Button
          inline
          size={Button.sizes.S}
          onClick={onAccept}
          disabled={disabled}
          data-qa="AcceptEnquiry:Button--accept"
        >
          {t.accept}
        </Button>
        {platformName === BOOKING_PLATFORMS.AIRBNB && (
          <Button
            inline
            size={Button.sizes.S}
            variation={Button.variations.OUTLINE}
            icon={{ icon: Button.icon.icons.CROSS }}
            onClick={onDecline}
            disabled={disabled || !onDecline}
            hint={{ enabled: true, text: t.declineInquiryHint }}
            data-qa="AcceptEnquiry:Button--decline"
          />
        )}
        {platformName === BOOKING_PLATFORMS.VRBO && (
          <DropdownButton
            horizontalPosition={DropdownButton.horizontalPositions.RIGHT}
            variation={DropdownButton.variations.OUTLINE}
            icon={{ icon: DropdownButton.icon.icons.ARROW_DOWN, position: DropdownButton.icon.positions.RIGHT }}
            size={DropdownButton.sizes.S}
            hasGap={false}
            disabled={disabled || !onDecline}
            renderButtonContent={() => t.decline}
            renderDropdownContent={({ closeDropdown }) => (
              <DropdownList>
                <DropdownListItem
                  onClick={() => {
                    onDecline(vrboDeclineReasons.cancelledByGuest);
                    closeDropdown();
                  }}
                >
                  {t.cancelledByGuest}
                </DropdownListItem>
                <DropdownListItem
                  onClick={() => {
                    onDecline(vrboDeclineReasons.cancelledByOwner);
                    closeDropdown();
                  }}
                >
                  {t.cancelledByOwner}
                </DropdownListItem>
              </DropdownList>
            )}
          />
        )}
      </ActionsWrapper>
    </ContentWrapper>
  </ChatNotification>
);

AcceptEnquiry.propTypes = {
  onDecline: PropTypes.func,
  onAccept: PropTypes.func.isRequired,
  onSendSpecialOffer: PropTypes.func,
  disabled: PropTypes.bool,
  platformName: PropTypes.string.isRequired,
  t: PropTypes.shape({
    decline: PropTypes.string,
    accept: PropTypes.string,
    showMore: PropTypes.string,
    showLess: PropTypes.string,
    specialOffer: PropTypes.string,
    specialOfferHint: PropTypes.string,
    declineInquiryHint: PropTypes.string,
    cancelledByGuest: PropTypes.string.isRequired,
    cancelledByOwner: PropTypes.string.isRequired,
  }),
};

export default AcceptEnquiry;
