import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import { getBooking } from 'selectors/booking';
import { updateInquiryRequested } from 'ducks/booking';
import { getConversation } from 'selectors/conversation';

import AcceptEnquiry from './components/AcceptEnquiry';
import EditSpecialOffer from './components/EditSpecialOffer';
import PreApprovalInstructions from './components/PreApprovalInstructions';
import BlacklistWarning from './components/BlacklistWarning';
import GuestIsVIPWarning from './components/GuestIsVIPWarning';
import PendingPaymentWarning from './components/PendingPaymentWarning';
import WhatsAppConversationWarning from './components/WhatsAppConversationWarning';
import Relocations from './components/Relocations';
import CancellationRequest from './components/CancellationRequest';
import { ChatNotification } from 'components';
import { Text, Notification } from 'ready/components';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { hasErrors } from 'utils/index';
import {
  isEligibleForPreApproval,
  isBookingNotFound,
  isTentativeOrigin,
  shouldDisplayPreApprovalInstructions,
  isBookingRelocated,
  canInquiryBeDeclined,
  isEligibleForSpecialOffer,
} from 'utils/businessLogic';
import { inquiryStatusPayload } from 'utils/deserializers';
import {
  getBookingSpecialInstructions,
  getBookingPreApprovalInstructions,
  getBookingBlacklistReasons,
  getBookingCancellationRequest,
} from 'utils/serializers';
import { bookingPropTypeWithState, inquiryPropShape, conversationPropTypeWithState } from 'common/propTypes';
import { inquiryStatuses, contexts, messageOrigins } from 'constants.js';
import { openSpecialOfferCreateForm } from 'ducks/conversationDrawer';
import {
  fetchSpecialOfferPriceRequested,
  deleteSpecialOfferRequested,
  changeShowSpecialOfferWithdrawModal,
} from 'ducks/specialOffer';
import { getSpecialOffer } from 'selectors/specialOffer';

import moment from 'moment';

const Wrapper = styled.div`
  border-top: ${props => props.theme.app.border.default};
`;

const Notifications = ({
  booking,
  conversation,
  specialOffer,
  dispatchUpdateInquiryRequested,
  dispatchOpenSpecialOfferCreateForm,
  dispatchFetchSpecialOfferPriceRequested,
  dispatchDeleteSpecialOfferRequested,
  dispatchChangeShowSpecialOfferWithdrawModal,
  t,
}) => {
  const specialInstructions = getBookingSpecialInstructions(booking.data);
  const preApprovalInstructions = getBookingPreApprovalInstructions(booking.data);
  const blacklistReasons = getBookingBlacklistReasons(booking.data);
  const cancellationRequest = getBookingCancellationRequest(booking.data);
  const guestsIsVip = booking.data && booking.data.guests_is_vip;
  const pendingPayment = booking.data && !booking.data.is_fully_paid;
  const isWhatsAppGuest = ((conversation.data || {}).guest || {}).origin == messageOrigins.WHATSAPP;

  const handleDeclineEnquiry = canInquiryBeDeclined(booking.data)
    ? (declineReason = null) => {
        dispatchUpdateInquiryRequested(
          booking.data.id,
          inquiryStatusPayload(inquiryStatuses.DENIED, declineReason),
          contexts.notifications.PRE_APPROVAL,
        );
      }
    : undefined;

  const handleSendSpecialOffer = isEligibleForSpecialOffer(booking.data)
    ? () => handleOpenSpecialOfferCreateForm(booking.data.id)
    : undefined;

  const handleAcceptEnquiry = () =>
    dispatchUpdateInquiryRequested(
      booking.data.id,
      inquiryStatusPayload(inquiryStatuses.PRE_APPROVED),
      contexts.notifications.PRE_APPROVAL,
    );

  const handleOpenSpecialOfferCreateForm = id => {
    dispatchOpenSpecialOfferCreateForm(id);
    dispatchFetchSpecialOfferPriceRequested(id);
  };

  return (
    <Wrapper data-qa="shared:Notifications__wrapper">
      {cancellationRequest && <CancellationRequest t={t} request={cancellationRequest} />}
      {isBookingNotFound(booking.data) && (
        <ChatNotification
          t={t}
          variation={Notification.variations.WARNING}
          data-qa="Notifications:ChatNotification--inquiry-not-found"
          collapseDisabled
        >
          <Text data-qa="Notifications:Text--inquiry-not-found">{t.inquiryNotFound}</Text>
        </ChatNotification>
      )}
      {isBookingRelocated(booking.data) && (
        <Relocations t={t} from={booking.data.relocated_from_id} to={booking.data.relocated_to_id} />
      )}
      {specialInstructions && (
        <ChatNotification t={t} data-qa="Notification:ChatNotification--rentalSpecialInstructions">
          <Text data-qa="Notifications:Text--rentalSpecialInstructions">{specialInstructions}</Text>
        </ChatNotification>
      )}
      {isEligibleForPreApproval(booking.data) &&
        (!booking.data.special_offer ||
          (booking.data.special_offer.expires_at > moment() || !booking.data.special_offer.is_expired)) && (
          <AcceptEnquiry
            onDecline={handleDeclineEnquiry}
            onAccept={handleAcceptEnquiry}
            onSendSpecialOffer={handleSendSpecialOffer}
            disabled={booking.isUpdating}
            platformName={booking.data.platform_name}
            t={t}
          />
        )}
      {isEligibleForSpecialOffer(booking.data) &&
        (booking.data.special_offer &&
          (booking.data.special_offer.expires_at > moment() || !booking.data.special_offer.is_expired)) && (
          <EditSpecialOffer
            onWithdrawOffer={() => dispatchDeleteSpecialOfferRequested(booking.data.special_offer.id)}
            onEditOffer={handleSendSpecialOffer}
            dispatchChangeShowSpecialOfferWithdrawModal={dispatchChangeShowSpecialOfferWithdrawModal}
            showSpecialOfferWithdrawModal={specialOffer.showSpecialOfferWithdrawModal}
            disabled={booking.isUpdating}
            expiresAt={booking.data.special_offer.expires_at}
            t={t}
          />
        )}
      {shouldDisplayPreApprovalInstructions(booking.data) && preApprovalInstructions && (
        <PreApprovalInstructions t={t}>{preApprovalInstructions}</PreApprovalInstructions>
      )}
      {blacklistReasons && <BlacklistWarning t={t} reasons={blacklistReasons} />}
      {guestsIsVip && <GuestIsVIPWarning t={t} />}
      {pendingPayment && <PendingPaymentWarning t={t} />}
      {isTentativeOrigin(booking.data) && (
        <ChatNotification
          t={t}
          variation={Notification.variations.WARNING}
          data-qa="Notifications:ChatNotification--tentative"
          collapseDisabled
        >
          <Text data-qa="Notifications:Text--tentative">
            <FormattedMessage
              id="CONVERSATION.TENTATIVE"
              values={{ platform_name: booking.data.platform_name.toUpperCase() }}
            />
          </Text>
        </ChatNotification>
      )}
      {hasErrors(booking.errors, contexts.notifications.PRE_APPROVAL) && (
        <Notification variation={Notification.variations.ERROR}>
          {isTentativeOrigin(booking.data) ? (
            <FormattedMessage
              id="CONVERSATION.TENTATIVE_PRE_APPROVAL_FAILED"
              values={{ platform_name: booking.data.platform_name.toUpperCase() }}
            />
          ) : (
            t.preApprovalFailed
          )}
        </Notification>
      )}
      {isWhatsAppGuest && <WhatsAppConversationWarning t={t} />}
    </Wrapper>
  );
};

Notifications.propTypes = {
  t: PropTypes.shape({
    showMore: PropTypes.string.isRequired,
    showLess: PropTypes.string.isRequired,
    decline: PropTypes.string.isRequired,
    accept: PropTypes.string.isRequired,
    edit: PropTypes.string.isRequired,
    withdraw: PropTypes.string.isRequired,
    preApprovalFailed: PropTypes.string.isRequired,
    tentativePreApprovalFailed: PropTypes.string.isRequired,
    preApprovalLabel: PropTypes.string.isRequired,
    blacklistLabel: PropTypes.string.isRequired,
    acceptEnquiryQuestion: PropTypes.string.isRequired,
    inquiryNotFound: PropTypes.string.isRequired,
    tentative: PropTypes.string.isRequired,
    vipGuestLabel: PropTypes.string.isRequired,
    vipGuestText: PropTypes.string.isRequired,
    pendingPayment: PropTypes.string.isRequired,
    whatsappConversationText: PropTypes.string.isRequired,
    specialOffer: PropTypes.string.isRequired,
    specialOfferHint: PropTypes.string.isRequired,
    declineInquiryHint: PropTypes.string.isRequired,
    confirmWithdraw: PropTypes.string.isRequired,
    withdrawDetails: PropTypes.string.isRequired,
    keepIt: PropTypes.string.isRequired,
    cancelledByGuest: PropTypes.string.isRequired,
    cancelledByOwner: PropTypes.string.isRequired,
  }),
  booking: bookingPropTypeWithState.isRequired,
  conversation: conversationPropTypeWithState.isRequired,
  specialOffer: inquiryPropShape.special_offer,
  dispatchUpdateInquiryRequested: PropTypes.func.isRequired,
  dispatchOpenSpecialOfferCreateForm: PropTypes.func.isRequired,
  dispatchFetchSpecialOfferPriceRequested: PropTypes.func.isRequired,
  dispatchDeleteSpecialOfferRequested: PropTypes.func.isRequired,
  dispatchChangeShowSpecialOfferWithdrawModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  booking: getBooking(state),
  conversation: getConversation(state),
  specialOffer: getSpecialOffer(state),
});

const mapDispatchToProps = {
  dispatchUpdateInquiryRequested: updateInquiryRequested,
  dispatchOpenSpecialOfferCreateForm: openSpecialOfferCreateForm,
  dispatchFetchSpecialOfferPriceRequested: fetchSpecialOfferPriceRequested,
  dispatchDeleteSpecialOfferRequested: deleteSpecialOfferRequested,
  dispatchChangeShowSpecialOfferWithdrawModal: changeShowSpecialOfferWithdrawModal,
};

export default compose(
  withMappedTranslations({
    showMore: 'TEXT.SHARED.SHOW_MORE',
    showLess: 'TEXT.SHARED.SHOW_LESS',
    decline: 'TEXT.SHARED.DECLINE',
    accept: 'TEXT.SHARED.ACCEPT',
    edit: 'TEXT.SHARED.EDIT',
    withdraw: 'TEXT.SHARED.WITHDRAW',
    preApprovalFailed: 'CONVERSATION.PRE_APPROVAL.UPDATE_FAILED',
    preApprovalLabel: 'CONVERSATION.PRE_APPROVAL.LABEL',
    blacklistLabel: 'CONVERSATION.BLACKLIST.LABEL',
    acceptEnquiryQuestion: 'CONVERSATION.ACCEPT_ENQUIRY_QUESTION',
    inquiryNotFound: 'CONVERSATION.INQUIRY_NOT_FOUND',
    vipGuestLabel: 'CONVERSATION.VIPGUEST.LABEL',
    vipGuestText: 'CONVERSATION.VIPGUEST.TEXT',
    pendingPayment: 'CONVERSATION.PENDING_PAYMENT.TEXT',
    whatsappConversationText: 'CONVERSATION.WHATSAPP_CONVERSATION',
    specialOffer: 'SPECIAL_OFFER.TITLE',
    specialOfferHint: 'SPECIAL_OFFER.HINT',
    declineInquiryHint: 'CONVERSATION.DECLINE_INQUIRY_HINT',
    confirmWithdraw: 'SPECIAL_OFFER.CONFIRM_WITHDRAW',
    withdrawDetails: 'SPECIAL_OFFER.WITHDRAW_DETAILS',
    keepIt: 'SPECIAL_OFFER.KEEP',
    cancelledByGuest: 'BOOKING.INQUIRY.CANCELLED_BY_GUEST',
    cancelledByOwner: 'BOOKING.INQUIRY.CANCELLED_BY_OWNER',
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Notifications);
