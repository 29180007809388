import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { fetchRentalRequested } from 'ducks/rental';
import { getRental } from 'selectors/rental';

import { withMemoMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { fullRentalPropTypeWithState, fullRentalPropType, reservationsPropType } from 'common/propTypes';

import {
  CenteredLoadingIndicator,
  CenteredRequestError,
  TabContentWrapper,
  TabWithForm,
  FormRow,
} from '../../components';
import Issue, { Card } from './components/Issue';

import SelectProperty from '../../components/SelectProperty';
import { Button, Notification, Select } from 'ready/components';
import { StyledNotification } from '../Overview/components/Guest/components/GuestVerifiedNotification';

const NoReservationNotification = ({ t, reservationId }) => {
  if (reservationId) return '';
  return <StyledNotification variation={Notification.variations.ERROR}>{t.noReservation}</StyledNotification>;
};

const IssuesContent = ({ t, rental, reservations }) => {
  const [reservationOptions, setReservationsOptions] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState();
  const [reservationId, setReservationId] = useState();
  const [rentalId, setRentalId] = useState();

  const getCreateURL = (reservationId, rentalId, mode) => {
    const baseURLs = {
      ticket: `${process.env.REACT_APP_BASE_API_URL}/app/issues/create?`,
      mission: `${process.env.REACT_APP_BASE_API_URL}/staffing/missions/create/?`,
    };

    const baseURL = baseURLs[mode] || '';

    if (reservationId) return `${baseURL}reservation=${reservationId}&origin=INBOX_THREAD_CREATED`;
    if (mode === 'mission' && rentalId) return `${baseURL}appartement=${rentalId}`;
    if (mode === 'ticket' && rentalId) return `${baseURL}rental=${rentalId}&origin=INBOX_THREAD_CREATED`;

    return baseURL;
  };

  useEffect(() => {
    setReservationsOptions(
      reservations.reverse().map(reservation => ({ value: reservation.id, label: reservation.id })),
    );
  }, [reservations]);

  useEffect(() => {
    if (reservationOptions) {
      setSelectedReservation(reservationOptions[0]);
    }
  }, [reservationOptions]);

  useEffect(() => {
    if (selectedReservation) {
      setReservationId(selectedReservation.value);
    }
  }, [selectedReservation]);

  useEffect(() => {
    if (rental && rental.data) {
      setRentalId(rental.data.id);
    }
  }, [rental]);

  useEffect(() => {}, [selectedReservation]);

  if (rental.isFetching) return <CenteredLoadingIndicator />;

  if (rental.didInvalidate) return <CenteredRequestError errors={rental.errors} />;

  if (!rental.data) return false;

  return (
    <TabContentWrapper style={{ overflowY: rental.data.issues.length ? 'inherit' : 'initial' }}>
      <Card style={{ padding: '2.4rem' }}>
        {reservations.length > 1 && (
          <div style={{ marginBottom: '1.2rem' }}>
            <Select
              closeMenuOnSelect
              value={selectedReservation}
              onChange={option => setSelectedReservation(option)}
              options={reservationOptions}
              t={t}
            />
          </div>
        )}
        <NoReservationNotification t={t} reservationId={reservationId} />
        <Button
          style={{ marginBottom: '1.2rem' }}
          variation={Button.variations.OUTLINE}
          target="_blank"
          href={getCreateURL(reservationId, rentalId, 'ticket')}
        >
          {t.createTicket}
        </Button>
        <Button
          variation={Button.variations.OUTLINE}
          target="_blank"
          href={getCreateURL(reservationId, rentalId, 'mission')}
        >
          {t.createMission}
        </Button>
      </Card>
      {!!rental.data.issues.length && rental.data.issues.map(issue => <Issue key={issue.id} t={t} {...issue} />)}
    </TabContentWrapper>
  );
};

IssuesContent.propTypes = {
  rental: fullRentalPropTypeWithState.isRequired,
  reservations: PropTypes.arrayOf(reservationsPropType),
  t: PropTypes.object.isRequired,
};

const Issues = ({ t, rental, rentals, dispatchFetchRentalRequested, reservations }) => {
  return (
    <TabWithForm data-qa="Tab:__innerWrapper--issues">
      {rentals.length > 1 ? (
        <FormRow>
          <SelectProperty
            rentals={rentals}
            rental={rental.data}
            t={t}
            onChange={option => dispatchFetchRentalRequested(option.value)}
          />
        </FormRow>
      ) : (
        <div />
      )}
      <IssuesContent rental={rental} reservations={reservations} t={t} />
    </TabWithForm>
  );
};

Issues.propTypes = {
  dispatchFetchRentalRequested: PropTypes.func.isRequired,
  rentals: PropTypes.arrayOf(fullRentalPropType).isRequired,
  ...IssuesContent.propTypes,
  reservations: PropTypes.arrayOf(reservationsPropType),
};

const mapStateToProps = state => ({
  rental: getRental(state),
});

const mapDispatchToProps = {
  dispatchFetchRentalRequested: fetchRentalRequested,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMemoMappedTranslations({
    issue: 'INFO_SIDEBAR.ISSUES.ISSUE',
    category: 'INFO_SIDEBAR.ISSUES.CATEGORY',
    comment: 'INFO_SIDEBAR.ISSUES.COMMENT',
    origin: 'INFO_SIDEBAR.ISSUES.ORIGIN',
    openedBy: 'INFO_SIDEBAR.ISSUES.OPENED_BY',
    openedOn: 'INFO_SIDEBAR.ISSUES.OPENED_ON',
    selectProperty: 'INFO_SIDEBAR.PROPERTY.SELECT_PROPERTY',
    noResultsText: 'TEXT.SHARED.NO_RESULTS',
    createTicket: 'INFO_SIDEBAR.ISSUES.CREATE_TICKET',
    createMission: 'INFO_SIDEBAR.ISSUES.CREATE_MISSION',
    noReservation: 'INFO_SIDEBAR.ISSUES.NO_RESERVATION',
  }),
)(Issues);
